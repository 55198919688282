/* Layout */
$pad: 1rem;

$breakpoint-xs: 320px;
$breakpoint-sm: 375px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1280px;
$breakpoint-xxl: 1440px;

$breakpoint-xs-min: 0px;
$breakpoint-sm-min: ($breakpoint-sm + 1);
$breakpoint-md-min: ($breakpoint-md + 1);
$breakpoint-lg-min: ($breakpoint-lg + 1);
$breakpoint-xl-min: ($breakpoint-xl + 1);
$breakpoint-xxl-min: ($breakpoint-xxl + 1);

$breakpoint-xs-max: $breakpoint-sm;
$breakpoint-sm-max: $breakpoint-md;
$breakpoint-md-max: $breakpoint-lg;
$breakpoint-lg-max: $breakpoint-xl;
$breakpoint-xl-max: $breakpoint-xxl;

/* Color */
$primary: #428071;
$primary-dark: #035642;

$gray-text: #757575;
$gray-icon: #676a6c;

$link: #00779E;

/* Badges */
$badge-corner-radius: 16px;
$badge-height: 25px;
