@import './_variables';
@import './_mixins';

html,
body {
  overflow-x: hidden;
  height: 100%;
}

body {
  background-color: white;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
  
}

a:link,
a:visited {
  color: $link;
  text-decoration: none;
}

a:hover,
a:active {
  text-decoration: underline;
}

a:active {
  color: lighten($link, 10%);
}

.dead-link{
  color: #00779E;
  text-decoration: none;
  cursor: pointer;
}

ul {
  padding-left: 1em;
  margin: 0;
}

.small {
  font-size: 90%;
}

h2 {
  font-size: 20px;
  font-weight: lighter;
}

h4 {
  font-size: 14px;
  text-transform: uppercase;
  color: $gray-text;
  font-weight: lighter;
  margin-bottom: 15px;
}

.pad-top {
  padding-top: $pad !important;
}
.pad-right {
  padding-right: $pad !important;
}
.pad-bottom {
  padding-bottom: $pad !important;
}
.pad-left {
  padding-left: $pad !important;
}
.pad-y {
  padding-top: $pad !important;
  padding-bottom: $pad !important;
}
.pad-x {
  padding-left: $pad !important;
  padding-right: $pad !important;
}

.pad-input-top {
  margin-top: 10px !important;
}
.pad-input-right {
  margin-right: 10px !important;
}
.pad-input-bottom {
  margin-bottom: 10px !important;
}
.pad-input-left {
  margin-left: 10px !important;
}

.shorter {
  width: 97%;
}

.label {
  font-weight: 500;
  color: #757575;
}

.inner {
  display: table;
  margin: 0 auto;
}
.margin0auto{
  margin: 0 auto;
  width: fit-content;
  display: block;
}

.green,.green:hover{
  background-color: #487F69 !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: white;
  border-radius: 5px;
}

.white,.white:hover{
  background: #FFFFFF !important;
  border: 1px solid #EBEBED;
  box-sizing: border-box;
  box-shadow: 0px 5px 15px rgba(46, 46, 46, 0.09);
  border-radius: 8px;
  color: #1E1E20;
  font-weight: 700;
  font-size: 14px;
  padding: 6px 0;
}

.outer {
  width: 100%;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.required:after {
  color: #d00;
  content: '*';
  position: absolute;
  margin-left: 5px;
  top: 7px;
}

.confirmation-popup {
  h1:first-child,
  h2:first-child,
  h3:first-child {
    margin-top: 0 !important;
  }
}

.return-link {
  display: inline-block;
  font-size: 80%;
  padding-bottom: 30px;
  text-decoration: none;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.fa {
  color: $gray-icon;
}

/* DevExpress DevExtreme overrides */
/* TODO: Move to theme */

.dx-invalid .dx-checkbox-container .dx-checkbox-icon {
  border: 2px solid rgba(217, 83, 79, 1);
}

.nav-subitems .nav-subitem {  
  flex: 1 0 auto !important;
}

.dx-group-cell {
  background-color: #ebebed;
  color: #292b2c;
}

.dx-datagrid .dx-row-alt > td {
  background-color: #f2f2f2;
}

.dx-datagrid .dx-row > td {
  padding: 12px;
}

.dx-datagrid-table .dx-data-row.dx-state-hover {
  cursor: pointer;
}

.dx-datagrid-table .rowLinkCell {
  vertical-align: middle !important;
  text-align: center !important;
  color: #999999;
}

.dx-scheduler{
  width: 100%;
  .dx-scheduler-agenda-date{
    font-size: 28px;
    font-weight: bold;
    color: #39393B;
    text-align: center;
    .dx-scheduler-agenda-day{
      margin-top: 8px;
    }
    .dx-scheduler-agenda-weekday{
      font-size: 16px;
      font-weight: normal;
      margin-top: -3px;
    }
  }
  .dx-scheduler-agenda-time{
    position: relative;
    top:-8px;
    .dx-scheduler-agenda-hour,.dx-scheduler-agenda-ampm{
      text-align: center;
      color: #39393B;
      font-weight: bold;
    }    
  }
}

.dx-scheduler-agenda{
  .dx-scheduler-appointment {
    &.dx-scheduler-appointment-horizontal{
      margin-top: 10px !important;
      width: calc(100% - 12px) !important;
    }  
  }  
}
.dx-scheduler-work-space-month{
  .dx-scheduler-appointment, .dx-state-hover{
    background-color: #D8EFE7 !important;
    color: #1E1E20 !important;
    box-shadow: inset 3px 0 0 0 #0E856F !important;
  }
}
.dx-scheduler-agenda, .dx-scheduler-work-space-day,.dx-scheduler-work-space-week{
  .dx-scheduler-appointment {
    background-color: #D8EFE7 !important;
    color: #1E1E20 !important;
    border-radius: 4px;
    padding-left: 0px;
    padding-top: 5px;
    overflow: hidden;
    
    .dx-scheduler-appointment-content{
      overflow: visible;
      .dx-scheduler-agenda-appointment-left-layout{
        display: none;
        visibility: hidden;
      }
      .dx-scheduler-agenda-appointment-right-layout{
        overflow: visible;
      }
    }
    &.dx-scheduler-appointment-horizontal{
      margin-top: 10px !important;
      width: calc(100% - 12px) !important;
    }
    &.dx-scheduler-appointment-vertical{
      box-shadow: inset 0 3px 0 0 #0E856F;
      margin-right: -25px;
    }
    .dx-scheduler-appointment-title{
      font-weight: bold !important;
      font-size: 16px !important;
    }
    .dx-scheduler-appointment-content-date{
      font-weight: normal !important;
      font-size: 16px !important;
      margin-top: 5px !important;
      color: black;
    }
  }
}
.dx-scheduler-header-row:before{
  width: 75px;
}
.dx-scheduler-time-panel{
  width: 75px;
  .dx-scheduler-time-panel-row:first-of-type{
    .dx-scheduler-time-panel-cell > div{
      margin-top: -13px;
    }
    .dx-scheduler-time-panel-cell:before{
      position: absolute;
      top: 0px;
      width: 10%;
      height: 1px;
      content: '';
      right: 0;
      background-color: rgba(221,221,221,.6);
      box-sizing: border-box;
    }
  }
}

.dx-scheduler-view-switcher.dx-tabs .dx-tab.dx-state-focused:after {
  border-top-color: $primary !important;
}

.dx-scheduler-work-space-day{
  .dx-scheduler-date-table-scrollable{
    padding-top: 30px;
  }
}
.dx-scheduler-date-table-row{
  padding-right: 20px;
  &:first-of-type{
    .dx-scheduler-date-table-cell{
      border-top: 1px solid rgba(221,221,221,.6) !important;
    }
  }
}
.dx-scheduler-work-space-week .dx-scheduler-header-panel{
  width: calc(100% - 25px);
}

.dx-scheduler-all-day-table-cell.dx-state-hover,
.dx-scheduler-date-table-cell.dx-state-hover,
.dx-scheduler-all-day-table-cell.dx-state-focused,
.dx-scheduler-date-table-cell.dx-state-focused {
  background-color: transparent !important;
}

.dx-scheduler-all-day-table-cell.dx-scheduler-focused-cell,
.dx-scheduler-date-table-cell.dx-scheduler-focused-cell {
  box-shadow: none !important;
}

.dx-scheduler-dropdown-appointment {
  border-left-color: $primary !important;
}

.dx-scheduler-dropdown-appointments.dx-button,
.dx-scheduler-dropdown-appointments.dx-button.dx-state-hover,
.dx-scheduler-dropdown-appointments.dx-button.dx-state-active,
.dx-scheduler-dropdown-appointments.dx-button.dx-state-focused {
  background-color: $primary !important;
}

@media (max-width: 640px){
  .dx-scheduler{
    margin: -10px;  
    width:unset;
  }
  .dx-scheduler-work-space{
    border: unset;
  }
  .dx-scheduler-navigator{
    max-width: 100%;
    width: 100%;
    .dx-scheduler-navigator-previous,.dx-scheduler-navigator-next{
      border-radius: 0px;
      background-color: #F8F8F8;
      width: 48px;
      height: 48px;
      border-color: #9D9DA1;
      .dx-icon{
        color: #5C5C60;
      }
    }
    .dx-scheduler-navigator-caption{
      max-width: 100%;
      width: calc(100% - 48px - 48px);
      height: 48px;
      color: #5C5C60;
      border-color: #9D9DA1;
      font-size: 18px;
      @media (max-width: 370px) {
        font-size: 4vw;
      }
    }
  }
  .dx-scheduler-header{
    display: flex;
    flex-direction: column-reverse;
    height: 120px;
    padding-top: 20px;
    background-color: white;
    border: unset;
    .dx-tabs{
      border-bottom: 1px solid #DEDDE1 !important;
      width: 100%;
      display: block !important;
      max-width: 100%;
      .dx-tabs-wrapper{
        display: block;
        padding-left: 12px;
        .dx-tab{
          color: #5C5C60 !important;
          font-size: 18px;
          &.dx-tab-selected{
            color: #0E856F !important;
            font-weight: bold;
            &::before{
              background-color: unset !important;
            }
            &::after{
              border-top: unset !important;
              border-left: unset !important;
              border-right: unset !important;
              border-bottom: 2px solid #0E856F;
              height: unset;
            }
          }
        }
      }
    }
  }
}

.dx-fieldset {
  margin: $pad 0 !important;

  .dx-field-label {
    font-weight: 500;
    color: $gray-text;
  }

  // Note: dx-fieldset-label-on-top is a custom class we created, not built into DevExtreme
  &.dx-fieldset-label-on-top {
    .dx-field-label {
      clear: both;
      width: 100%;
    }

    .dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button),
    .dx-field-value-static {
      clear: both;
      width: 100%;
    }
  }
}

.dx-button-default {
  background-color: $primary-dark !important;
  border-color: darken($primary-dark, 10%) !important;

  &.dx-state-hover {
    background-color: lighten($primary-dark, 10%) !important;
    border-color: $primary-dark !important;
  }

  &.dx-state-focused {
    background-color: darken($primary-dark, 5%) !important;
    border-color: darken($primary-dark, 15%) !important;
  }
}

.dx-popup-bottom .dx-button {
  min-width: 75px !important;
}

.dx-datebox-native .dx-texteditor-container{
  pointer-events: none;
}


.dx-overlay-shader {
  background-color: rgba(128, 128, 128, 0.5) !important;
}

.dx-list-item.dx-state-active,
.dx-list-item.dx-state-focused {
  background-color: $primary-dark !important;
  div {
    color: white !important;
  }
}

.dx-item.dx-radiobutton {
  width:100%;
}

.dx-radio-value-container {
  width: 45px;
}

.dx-checkbox-text{
  font-size: 14px;
}


.dx-loadindicator-icon .dx-loadindicator-segment{
  width: 12%;
  background: #0B4B40;
  border-radius: 10%;
  border-radius: 3px;
  height: 28%;
}

@-webkit-keyframes dx-generic-loadindicator-opacity {
  from {
    opacity: 1;
    background:#0B4B40;
  }
  to {
    opacity: 1;
    background: #B2DECF;
  }
}
@keyframes dx-generic-loadindicator-opacity {
  from {
    opacity: 1;
    background:#0B4B40;
  }
  to {
    opacity: 1;
    background:#B2DECF;
  }
}

@include breakpoint(lt-md) {
  .dx-popup-title.dx-toolbar .dx-toolbar-label {
    font-size: 15px;
  }  
}

.red {
  color:red;
}

.telehealth-red {
  color: #fa4141;
}

.telehealth-yellow {
  color: #39393B;
  background-color: #FFF4CE;
  border: 1px solid #FED9CC;
  padding: 12px;
  margin-bottom: 12px;
  border-radius: 4px;
  .stretch-button{
    display: block;
    color: white;
    background-color: #026352;
    height: 48px;
    font-size: 18px;
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    margin-top: 12px;
    &:hover{
      color: white;
      background-color: #026352;
    }
    .dx-icon{
      color: white;
    }
  }
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.secure-message-popup-content {
  max-height: 500px !important;
}

.ctcatabs{
  border: unset;
  margin-top: 10px;
  .dx-tabs-wrapper{
    display: block;
    max-height: 45px;
    border-bottom: 1px solid #C4C4C8;
    .dx-tab{
      background-color: white;
      padding: 0px;
      &.dx-tab-selected{
        .dx-tab-content{
          font-weight: bold;
          color: #10856F;
          border-bottom: 2px solid #10856F;
        }
        &:after{
          border-left: unset;
          border-right: unset;
          border-top: unset;
        }
      }
      .dx-tab-content{
        color: #5C5C60;
        border-bottom: 2px solid transparent;
        margin: 0px 10px;
        padding: 0px 10px 10px 10px;
        margin-bottom: -1px;
      }
    }
  }
}

.ctcamailcard{
  .card-body{
    padding: 0px !important;
  }
}

.ctcasearchelement{
  background-color: #F8F8F8 !important;
  border-radius: 0px !important;
  border: unset !important;
  border-bottom: 1px solid #C4C4C8 !important;
  margin: 10px !important;

  .dx-icon-search{
    filter: sepia(10) saturate(10) hue-rotate(103deg) brightness(0.5);
  }
  .ctcasearchbox{
    color: #5C5C60;
  }
}

.ctca-button{
  border-radius: 0px;
  color: #1E1E20 !important;
  .dx-button-text{
    text-transform: none;
  }
}

.floating-button{
  position: fixed;
  right: 25px;
  bottom: 25px;
  color: white;
  background-color: #026352;
  height: 48px;
  font-size: 18px;
  &:hover{
    color: white;
    background-color: #026352;
  }

  .dx-icon{
    color: white;
  }
}


@media (max-width: 850px) {
  .desktop-only{
    display: none;
    visibility: hidden;
  }
}
@media (min-width: 851px) {
  .mobile-only{
    display: none;
    visibility: hidden;
  }
}

.square-corners{
  border-radius: 2px;
}

.customAlertMobile, .customAlertDesktop{
  .dx-button-content{
    padding: 9px 18px 10px;
  }
  .grayDialogButton{
    background-color: #EBEBED;
    color: #39393B;
    &:hover{
      background-color: #EBEBED;
    }
  }
  .greenDialogButton{
    background-color: #487F69;
    color: white;
    &:hover{
      background-color: #487F69;
    }
  }
  .hiddenButton{
    display: none;
    visibility: hidden;
    height: 0px !important;
  }
  
  .dialogText{
    font-size: 16px;
    color: #39393B;
    max-width: 650px;
    width: 90vw;
    font-weight: 400;
    position: relative;
    h1{
      margin-bottom: 24px;
      margin-top: 20px;
      font-weight: 700;
      font-size: 22px;
      color: #1E1E20;
    }
    a{
      text-decoration: none;
      color: #3176DD;
    }
    &.dialogTextBold{
      font-weight: bold;
      font-size: 19px;
    }
    &.center{
      text-align: center;
    }    
    .closeButton{
      position: absolute;
      top: 0px;
      right: 8px;
      cursor: pointer;
    }
  }

  .dx-toolbar{
    min-height: 104px;
    .dx-toolbar-center,.dx-toolbar-after{
      display: flex;
      flex-direction: row;
      .dx-item{
        flex: 1;
        .dx-button{
          margin-right: 0px;
          width: 100%;
          background-color: #487F69;
          border-radius: 8px;
          height: 64px;
          line-height: 64px;
          font-weight: 600;
          font-size: 16px;
          .dx-button-content{
            padding:0px;
            .dx-button-text{
              line-height: 64px;
            }
          }
        }
      }
    }
  }
  
}

.customAlertPopupMobile,.customAlertMobile{
  .dx-popup-normal{
    border-radius: 0px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    bottom: 0px;
    top: unset !important;
    position: fixed;
    width: 100% !important;
    transform: none !important;
    margin: 0 !important;
    height: unset !important;
    display: flex;
    flex-direction: column;

    .dx-toolbar-items-container{
      height: fit-content !important;
      .dx-toolbar-center,.dx-toolbar-after{
        display: flex;
        flex-direction: column-reverse;
        padding-left: 0px;
        position: relative;
        float: unset !important;
        margin: 0px !important;
        height: unset !important;

        height: fit-content !important;
        width: 100%;
        bottom: 0px;
        top: unset;
        .dx-toolbar-item{
          padding:0px;
          padding-bottom: 15px;
          &:first-of-type{
            padding-bottom: 0px;
          }
          .greenDialogButton,.grayDialogButton{
            width: 100%;
          }
        }
      }
    }
  }
}

.customAlertDesktop{
  .dx-popup-normal{
    width: auto !important;
  }
  .grayDialogButton{
    width: 150px;
    max-width: 40vw;
    margin-left: 30px;
  }
  .greenDialogButton{
    width: 150px;
    max-width: 40vw;
    margin-right: 30px;
  }
  .dialogText{
    margin: 0;
    margin-bottom: -15px;
    &.noButtons{
      margin-bottom: -80px;
    }
  }
}

.square-corners{
  border-radius: 2px;
}


.noCareTeam{
  text-align: right;
  color: #1E1E20;
  font-weight: 700;
  display: inline-block;
  margin-left: 42px;
  position: relative;
  top: 4px;
  .top{
    font-size: 12px;
  }
  .bottom{
    font-size: 16px;
    a{
      text-decoration: none;
      color: #10856F;
    }
  }
}

.noCareTeamLine, .noCareTeamFooter{
  font-size: 14px;
  text-align: center;
  color: #1E1E20;
  font-weight: 700;
  &.topMargin{
    margin-top: 20px;
  }
  &.sideMargins{
    margin: 0px 18px;
  }
  a{
    text-decoration: none;
    color: #10856F;
  }
  hr{
    border: unset;
    border-top: 1px solid #DDDDDD;
    margin-top: 20px;
  }
}

.noCareTeamFooter{
  background-color: #F8F8F8;
  padding: 8px;
}

.noCareTeamPrescriptions{
  text-align: center;
  color: #1E1E20;
  font-weight: 700;
  display: block;
  position: relative;
  font-size: 14px;
  a{
    text-decoration: none;
    color: #10856F;
  }
  hr{
    border: unset;
    border-top: 1px solid #DDDDDD;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.onlyDesktop{
  display: block;
  visibility: visible;
}
.onlyMobile{
  display: none;
  visibility: hidden;
}

@media (max-width: 640px) {
  .onlyDesktop{
    display: none;
    visibility: hidden;
  }
  .onlyMobile{
    display: block;
    visibility: visible;
  }
}
.dx-datagrid-headers {
  color: #757575;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>td {
  background-color: white;
}

.dx-placeholder {
  color: #757575;
}

.dx-datagrid-nodata {
  color: #757575;
}

.dx-invalid-message>.dx-overlay-content {
  background-color: #D4403A;
  color: white;
}

.dx-datagrid-group-panel .dx-group-panel-message {
  color: #757575;
}