@import './_variables';

@mixin breakpoint($point) {
    @if ($point == xs) {
        @media (max-width: $breakpoint-xs-max) { @content; }
    }
    @else if ($point == sm) {
        @media (min-width: $breakpoint-sm-min) and (max-width: $breakpoint-sm-max) { @content; }
    }
    @else if ($point == md) {
        @media (min-width: $breakpoint-md-min) and (max-width: $breakpoint-md-max) { @content; }
    }
    @else if ($point == lg) {
        @media (min-width: $breakpoint-lg-min) and (max-width: $breakpoint-lg-max) { @content; }
    }
    @else if ($point == xl) {
        @media (min-width: $breakpoint-xl-min) and (max-width: $breakpoint-xxl-max) { @content; }
    }
    @else if ($point == xxl) {
        @media (min-width: $breakpoint-xxl-min) { @content; }
    }
    @else if ($point == lt-sm) {
        @media (max-width: $breakpoint-xs-max) { @content; }
    }
    @else if ($point == lt-md) {
        @media (max-width: $breakpoint-sm-max) { @content; }
    }
    @else if ($point == lt-lg) {
        @media (max-width: $breakpoint-md-max) { @content; }
    }
    @else if ($point == lt-xl) {
        @media (max-width: $breakpoint-lg-max) { @content; }
    }
    @else if ($point == lt-xxl) {
        @media (max-width: $breakpoint-xl-max) { @content; }
    }
    @else if ($point == gt-xs) {
        @media (min-width: $breakpoint-sm-min) { @content; }
    }
    @else if ($point == gt-sm) {
        @media (min-width: $breakpoint-md-min) { @content; }
    }
    @else if ($point == gt-md) {
        @media (min-width: $breakpoint-lg-min) { @content; }
    }
    @else if ($point == gt-lg) {
        @media (min-width: $breakpoint-xl-min) { @content; }
    }
    @else if ($point == gt-xl) {
        @media (min-width: $breakpoint-xxl-min) { @content; }
    }
}
